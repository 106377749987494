<template>
    <div class="kitchen-filter-container">
        <div class="kitchen-filters">
            <div class="filter-action">
                <p>Filter</p>
                <p @click="resetAllFilters">Reset</p>
            </div>
            <div class="filters">
                <div
                    v-for="data in kitchenFilters.data.vertical_filter"
                    :key="data.filter"
                    class="filter"
                >
                    <p class="filter-name">{{ data.filter }}</p>
                    <div class="options">
                        <div v-for="type in data.data" :key="type.option">
                            <div
                                class="option"
                                @click="addFilters(data.filter, type.option)"
                                :id="`${data.filter}-${type.option}`"
                            >
                                {{ type.option }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="all-kitchens" v-if="kitchenFilters.responseCode == 200">
            <div class="kitchen-types">
                <div
                    v-for="kitchen in kitchenFilters.data.horizontal_filters"
                    :key="kitchen.quotes_type"
                    class="kitchen-type"
                    :class="
                        kitchen.quotes_type === selectedKitchenType
                            ? 'selected-kitchen-type'
                            : ''
                    "
                    @click="changeKitchenConfig(kitchen.quotes_type)"
                >
                    <template
                        v-if="kitchen.quotes_type === selectedKitchenType"
                    >
                        <img
                            :src="kitchen.selected_quotes_type_image"
                            class="type-image"
                        />
                    </template>
                    <template v-else>
                        <img
                            :src="kitchen.quotes_type_image"
                            class="type-image"
                        />
                    </template>
                    <p class="type-text">{{ kitchen.quotes_type }}</p>
                    <p class="type-range">
                        ₹ {{ convertToIndianNumberSystem(kitchen.min_value) }}-₹
                        {{ convertToIndianNumberSystem(kitchen.max_value) }}
                    </p>
                </div>
            </div>
            <div
                class="available-kitchens"
                v-if="kitchenQuotes.data.length > 0"
            >
                <div
                    v-for="quote in kitchenQuotes.data"
                    :key="quote.id"
                    class="kitchen"
                >
                    <img :src="quote.image_url" />
                    <div class="vendor-image">
                        <img :src="quote.vendor_image" />
                    </div>
                    <div class="kitchen-details">
                        400 sqft | {{ quote.project }}
                    </div>
                    <div class="overlay"></div>
                </div>
            </div>
            <div v-else class="available-kitchens">No data available</div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import CustomizeKitchenModule from 'store/modules/CustomizeKitchenPage'
import ConvertToPrice from 'mixins/ConvertToPrice'
export default {
    name: 'KitchenFilterPage',
    components: {},
    mixins: [RegisterStoreModule, ConvertToPrice],
    data() {
        return {
            selectedKitchenType: '',
            selectedFilters: [],
            kitchenPayload: {},
        }
    },
    computed: {
        ...mapState({
            kitchenFilters: state => state.CustomizeKitchenPage.kitchenFilters,
            kitchenQuotes: state => state.CustomizeKitchenPage.kitchenQuotes,
        }),
    },
    created() {
        this.registerStoreModule('CustomizeKitchenPage', CustomizeKitchenModule)
        this.fetchKitchenFilters().then(response => {
            this.selectedKitchenType =
                response.data.horizontal_filters[0].quotes_type
            let obj = {
                filter: 'quotes_type',
                options: response.data.horizontal_filters[0].quotes_type,
            }
            this.kitchenPayload = { horizontal_filter: obj }
            this.fetchAllKitchenQuotes(this.kitchenPayload)
        })
    },
    methods: {
        ...mapActions({
            fetchKitchenFilters: 'fetchKitchenFilters',
            fetchAllKitchenQuotes: 'fetchAllKitchenQuotes',
        }),
        changeKitchenConfig(type) {
            this.selectedKitchenType = type
            this.kitchenPayload.horizontal_filter.options = this.selectedKitchenType
            this.fetchAllKitchenQuotes(this.kitchenPayload)
        },
        addFilters(filter, option) {
            if (
                this.selectedFilters.some(
                    option => option.filter_type == filter,
                )
            ) {
                this.selectedFilters.forEach((filtr, index) => {
                    if (filtr.filter_type == filter) {
                        let opt = document.getElementById(
                            `${filtr.filter_type}-${filtr.option_name}`,
                        )
                        opt.classList.remove('selected-option')
                        this.selectedFilters[index].option_name = option
                    }
                })
                let opt = document.getElementById(`${filter}-${option}`)
                opt.classList.add('selected-option')
            } else {
                this.selectedFilters.push({
                    filter_type: filter,
                    option_name: option,
                })
                let opt = document.getElementById(`${filter}-${option}`)
                opt.classList.add('selected-option')
            }
            let vert = []
            this.selectedFilters.forEach(data => {
                vert.push({
                    filter: data.filter_type,
                    options: data.option_name,
                })
            })
            let hori = {
                filter: 'quotes_type',
                options: this.selectedKitchenType,
            }

            this.kitchenPayload = {
                horizontal_filter: hori,
                veritical_filters: vert,
            }
            this.fetchAllKitchenQuotes(this.kitchenPayload)
        },
        resetAllFilters() {
            this.selectedFilters = []
            let obj = document.querySelectorAll('.selected-option')
            obj.forEach(element => {
                element.classList.remove('selected-option')
            })
            delete this.kitchenPayload.veritical_filters
            this.fetchAllKitchenQuotes(this.kitchenPayload)
        },
    },
}
</script>
<style lang="scss" scoped>
@import './KitchenFilterPage.scss';
</style>
